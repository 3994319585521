<template>
  <GrModal
    v-model="isModalOpen"
    :close="canClose"
    :renderTriggerTo="'#TeleporRenderModalSaleExpress'"
    centered
  >
    <template #open>
      <slot></slot>
    </template>
    <GrModalContent
      :customWidth="'584'"
      :customHeight="'561'"
      :loading="modalLoading"
    >
      <GrModalHeader v-if="firstView" :isSaleExpress="true">
        <img src="@/assets/img/sale-express/image.png" alt="text2sell" class="logo-express">
      </GrModalHeader>
      <GrModalHeader v-else :isSaleExpress="true"/>
      <div v-if="firstView" class="w-100">
        <p class="text-custom-subtitle mb-4 w-100">{{ $t('shared.modal_sale_express.subtitle') }}</p>
        <div class="d-flex w-100">
          <div>
            <img class="mb-2" src="@/assets/img/icons/step-one-enable.svg">
            <img class="ml-4 mb-3" v-if="!isOffersSelectDisabled" src="@/assets/img/icons/step-line-enable.svg">
            <img class="mb-2" v-if="!isOffersSelectDisabled" src="@/assets/img/icons/step-two-enable.svg">
            <img class="ml-4 mb-3" v-if="!buttonConfirmDisabled" src="@/assets/img/icons/step-line-enable.svg">
            <img v-if="!buttonConfirmDisabled" src="@/assets/img/icons/step-three-enable.svg">

            <img class="ml-4 mb-3" v-if="isOffersSelectDisabled" src="@/assets/img/icons/step-line-disabled.svg">
            <img class="mb-2" v-if="isOffersSelectDisabled" src="@/assets/img/icons/step-two-disabled.svg">
            <img class="ml-4 mb-3" v-if="buttonConfirmDisabled" src="@/assets/img/icons/step-line-disabled.svg">
            <img class="mb-2" v-if="buttonConfirmDisabled" src="@/assets/img/icons/step-three-disabled.svg">
          </div>

          <div class="flex-grow-1 w-100">
            <!-- Seleção de Produtos -->
            <div class="mb-5">
              <div class="actions-label">
                <b-form-group :label="$t('shared.modal_sale_express.products_select')">
                  <multiselect
                    id="selectProducts"
                    v-model="selectedProduct"
                    v-validate="'required'"
                    label="name"
                    track-by="id"
                    :placeholder="$t('shared.modal_sale_express.search_products')"
                    :options="products"
                    @select="fetchOffers"
                  >
                  </multiselect>
                </b-form-group>
              </div>
            </div>

            <!-- Seleção de Ofertas -->
            <div class="mb-5">
              <div class="actions-label">
                <b-form-group :label="$t('shared.modal_sale_express.offers_select')">
                  <multiselect
                    id="selectOffers"
                    v-model="selectedOffer"
                    v-validate="'required'"
                    label="name"
                    track-by="id"
                    :placeholder="$t('shared.modal_sale_express.search_offers')"
                    :options="offers"
                    :disabled="isOffersSelectDisabled"
                  >
                  </multiselect>
                </b-form-group>
              </div>
            </div>

            <!-- Botão de Enviar -->
            <div>
              <div class="actions-label">
                <ProgressBaseButton
                  variant="primary"
                  :class="{'heaven': isHeaven}"
                  class="send-button"
                  :loading="modalLoading"
                  :disabled="modalLoading || buttonConfirmDisabled"
                  :loadingDuration="apiCallDuration"
                  @click="onSubmit"
                >
                  {{ $t('shared.modal_sale_express.button_send') }}
                </ProgressBaseButton>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="icon-sended">
          <img src="@/assets/img/icons/second-sale-send.svg" />
        </div>
        <div class="text-custom-sended-title">
          {{ $t('shared.modal_sale_express_sended.title') }}
        </div>
        <div class="text-custom-sended">
          {{ $t('shared.modal_sale_express_sended.subtitle') }}
        </div>
        <GrModalClose>
          <BaseButton
            class="button-sended"
            :disabled="modalLoading"
            @click="cancel()"
          >
            {{ $t('shared.modal_sale_express_sended.button_close') }}
          </BaseButton>
        </GrModalClose>
      </div>
    </GrModalContent>
  </GrModal>
</template>

<script>
import _ from "lodash";
import {mapGetters} from "vuex";
import Money from "@/mixins/money";
import Multiselect from "vue-multiselect";
import BaseButton from "../../BaseButton.vue";
import ProgressBaseButton from "../../ProgressBaseButton.vue";
import ProductService from "@/services/resources/ProductService";
import SaleExpressService from "@/services/resources/SaleExpressService";
import {
  GrModal,
  GrModalContent,
  GrModalHeader,
  GrModalClose
} from "@/components/GrComponents";

const serviceProduct = ProductService.build();
const serviceSaleExpress = SaleExpressService.build();

export default {
  name: 'SaleExpress',
  components: {
    BaseButton,
    Multiselect,
    ProgressBaseButton,
    GrModal,
    GrModalContent,
    GrModalHeader,
    GrModalClose
  },
  mixins: [Money],
  data() {
    return {
      products: [],
      selectedProduct: null,
      offers: [],
      selectedOffer: null,
      loading: false,
      offersLoading: false,
      modalLoading: false,
      firstView: true,
      lastView: true,
      apiCallDuration: 2000,
      isModalOpen: false,
      canClose: true,
      size: "lg",
    }
  },
  computed: {
    ...mapGetters(['getFlags']),
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    },
    isOffersSelectDisabled() {
      if(this.selectedProduct) return false
      return true
    },
    buttonConfirmDisabled() {
      if (this.selectedProduct && this.selectedOffer) return false
      return true
    }
  },
  methods: {
    fetchProducts(search = null) {
      let data = {list: true}
      if (search) data.search = search
      this.products = []

      serviceProduct
        .search({...data, with_participants: true})
        .then((response) => {
          response.forEach((element) => {
            const format = element.format;
            // Filter products that are not allowed to be sold in the second sale
            if (this.getFlags.includes("feature_tickets") && format === 'EVENT') {
              return;
            }
            this.products.push({
              id: element.id,
              name: element.name,
              desc: element.description.substr(0, 30) + '...',
              img: element.images && element.images.length !== 0 && element.images[0].path ? element.images[0].path : ''
            })
          })
        })
        .catch((err) => {
          console.error(err)
          this.products = []
        })
        .finally(() => {
          this.loading = false
        })
    },
    async fetchOffers(product) {
      this.offersLoading = true
      this.offers = [];
      this.selectedOffer = null;

      serviceProduct.search({list: true, url: `${product.id}/offers`})
        .then(res => {
          let offers_map = res.map((offer) => {
            if (product.id == offer.product_id) {
              return {id: offer.id, name: offer.name}
            }
          })
          this.offers.push(...offers_map)
        })
        .catch((e) => {
          console.error('Error trying to fetch offers: ' + e.message);
          this.offers = []
        })
        .finally(() => {
          this.offersLoading = false;
        });
    },
    openModal(sale_id) {
      this.loading = true;
      this.resetLocalState();
      this.fetchProducts();
      this.sale_id = sale_id;
      this.firstView = true;
      this.lastView = false;
    },
    onSubmit() {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          this.$bvToast.toast(this.$t('shared.modal_sale_express.validation_warning_body'), {
            title: this.$t('shared.modal_sale_express.validation_warning_title'),
            variant: "warning",
            autoHideDelay: 5000,
            appendToast: true,
          });
          return;
        }

        this.modalLoading = true;
        const startTime = Date.now();

        let data = {
          sale_id: this.sale_id,
          product_id: this.selectedProduct.id,
          offer_id: this.selectedOffer.id
        };

        serviceSaleExpress
          .post('', data)
          .then((response) => {
            this.$bvToast.toast(this.$t('shared.modal_sale_express.validation_success_body'), {
              title: this.$t('shared.modal_sale_express.validation_success_title'),
              variant: "success",
              autoHideDelay: 5000,
              appendToast: true,
            });
            this.modalLoading = false;
            this.firstView = false;
            this.lastView = true;
            const endTime = Date.now();
            this.apiCallDuration = endTime - startTime;
          }).catch((err) => {
            console.error(err);
            this.modalLoading = false;
          })
      });
    },
    cancel(){
      this.$emit("close");
    },
    resetLocalState() {
      this.offers = [];
      this.selectedOffer = null;
      this.products = [];
      this.selectedProduct = null;
    }
  }
};
</script>

<style lang="scss" scoped>
.logo-express{
  width: 150px;
}

.form-control.heaven:hover {
  border-color: $base-color-heaven !important;
}

.text-custom-title {
  width: 341px;
  height: 27px;
  left: 32.5px;
  top: 36.5px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: #333333;
}

.text-custom-subtitle {
  width: 461.78px;
  height: 66px;
  left: 32.5px;
  top: 75px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  display: flex;
  align-items: center;
  color: #81858E;
}

.text-custom-sended-title {
  position: absolute;
  width: 270px;
  height: 30px;
  left: calc(50% - 270px/2 + 9px);
  top: 226.5px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: #333333;
}

.text-custom-sended {
  position: absolute;
  width: 428px;
  height: 78px;
  left: calc(50% - 428px/2);
  top: 271.5px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #81858E;
}

.icon-sended {
  position: absolute;
  width: 65px;
  height: 65px;
  left: 50%;
  top: 113.5px;
  transform: translateX(-50%);
}

.button-sended {
  position: absolute;
  width: 200px;
  height: 55px;
  left: calc(50% - 191.5px/2);
  top: 392.5px;
  background: rgba(0, 148, 136, 0.1);
  color: rgba(0, 148, 136, 1);
  border-radius: 10px;
}

.multiselect {
  width: 100% !important;
  height: 100% !important;
  max-width: 398.67px !important;
  max-height: 55px !important;
  border-radius: 10px;
}

.actions-label {
  margin-left: 35px;
}

.send-button {
  width: 100% !important;
  max-width: 398.67px !important;
  border-radius: 10px;
}
</style>
